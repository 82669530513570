import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { LuFileSearch2 } from "react-icons/lu";
import { Button, Checkbox, Icon, IconButton } from "@cbrebuild/blocks";
import Avatar from "../../nucleus/avatar/avatar";
import ConfirmationModal from "../../nucleus/confirmation-modal/confirmation-modal";
import dealsService from "../../services/deals-service";
import { getAttachmentsExtract } from "../../services/attachments-service";
import userEventService from "../../services/user-event-service";
import mockData from "./test/test.json";
import "./lease-abstraction.scss";
import userOptionsService from "../../services/user-options-service";
import LoadingIcon from "../../assets/images/svg/loading-icon.svg";
import { abstractionLeaseFile } from "../../redux/actions/deal-files-actions";
import FileModal from "./file-modal";
import { connect } from "react-redux";

class FileListItem extends React.Component {
  state = {
    tempPrivacy: this.props.file.privacy.toLowerCase() !== "team",
    tempTitle: this.props.file.title,
    showEditMode: false,
    showModal: false,
    showDataModal: false,
    processingCompleted: false,
    dropdownOpen: Array(mockData.results.extracted_response.length).fill(false),
    results: [],
    isResultEmpty: false,
    errorMessage: "",
    successMessage: "",
  };

  componentDidMount() {
    let lease_abstraction_enabled;
    userOptionsService
      .fetchFeatureFlags()
      .then(({ results }) => {
        lease_abstraction_enabled = results?.find(
          (featureFlag) => featureFlag.feature_flag === "lease_abstraction_flag"
        )?.enabled;
        this.setState({ lease_abstraction_flag: lease_abstraction_enabled });

        // Only check processing status if lease_abstraction_flag is enabled
        if (lease_abstraction_enabled) {
          this.checkProcessingStatus();

          // Call the method every 30 seconds
          this.intervalId = setInterval(this.checkProcessingStatus, 30000);
        }
      })
      .catch((error) => console.error("Error:", error));
  }

  componentWillUnmount() {
    // Clear the interval when the component is unmounted
    clearInterval(this.intervalId);
  }

  checkProcessingStatus = () => {
    const { file } = this.props;
    getAttachmentsExtract({ deal: file.deal, file: file.id }).then((res) => {
      if (
        res.result.length === 0 ||
        res.result[0].status_text.includes(
          "Exception occured while extracting data by file key"
        )
      ) {
        // If the result array is empty, set isResultEmpty to true
        this.setState({ processingCompleted: true });
        const errorMessage = this.getErrorMessage(res.result);
        const successMessage = this.getSuccessMessage(res.result);
        this.setState({ isResultEmpty: true, errorMessage, successMessage });
      } else {
        const isUnsupportedFileType = res.result[0].status_text.includes(
          "Document extraction is not processed for this file type"
        );
        const errorMessage = this.getErrorMessage(res.result);
        const successMessage = this.getSuccessMessage(res.result);
        this.setState({
          processingCompleted:
            res.result[0].status === "Success" ||
            res.result[0].status === "Failure" ||
            isUnsupportedFileType,
          results: res.result,
          isResultEmpty: false, // If the result array is not empty, set isResultEmpty to false
          errorMessage,
          successMessage,
        });
      }
      // If processing is completed, clear the interval
      if (this.state.processingCompleted) {
        clearInterval(this.intervalId);
      }
    });
  };

  openDataModal = () => {
    const { file } = this.props;
    getAttachmentsExtract({ deal: file.deal, file: file.id }).then((res) => {
      this.setState({
        processingCompleted:
          res.result[0].status === "Success" ||
          res.result[0].status === "Failure",
      });
      this.setState({ results: res.result });
    });
    this.setState({ showDataModal: true });
  };

  handleExtract = () => {
    if (!this.state.processingCompleted) {
      const { file } = this.props;
      getAttachmentsExtract({ deal: file.deal, file: file.id }).then((res) => {
        console.log(res);
        this.setState({
          processingCompleted:
            res.result[0].status === "Success" ||
            res.result[0].status === "Failure",
        });
        this.setState({ results: res.result });
      });
    }
  };

  getSuccessMessage = (results) => {
    const lastImportedTimestamp =
      results[0]?.data_extract?.last_imported_timestamp;
    if (lastImportedTimestamp && lastImportedTimestamp !== "") {
      return "Data import to voucher is complete.";
    }
    return "";
  };

  getErrorMessage = (results) => {
    if (results.length === 0) {
      // no message, no icon
      return "";
    } else if (
      // no icon, display error message for unsupported file type
      results[0]?.status_text.includes(
        "Document extraction is not processed for this file type"
      )
    ) {
      return "Document extraction is enabled only for PDF files.";
    } else if (
      // display loading icon, no error message for documents still being processed
      results[0]?.status === "Queued" ||
      results[0]?.status === "Processing"
    ) {
      return "";
    } else if (
      // no icon, display error message for failed documents
      results[0]?.status === "Failure"
    ) {
      return "Extraction failed, please re-upload the document.";
    }

    // Default case: otherwise it would return undefined.
    return "";
  };

  removeFileSelected = () => {
    // Get all file list items
    const fileItems = document.querySelectorAll(".file-list-item");
    // Remove the 'lease-abstraction-file-selected' class from all file list items
    fileItems.forEach((fileItem) => {
      fileItem.classList.remove("lease-abstraction-file-selected");
      // Get the file search icon of the file list item
      const fileSearchIcon = fileItem.querySelector(".fileSearchIcon");
      // Remove the 'disabled' class from the file search icon
      if (fileSearchIcon) {
        fileSearchIcon.classList.remove("disabled");
      }
    });
  };

  hideDataModal = () => {
    const { file, leaseFileUpdate } = this.props;
    this.setState({ showDataModal: false });
    localStorage.setItem(`leaseDeal_${file.deal}`, JSON.stringify(file));
    leaseFileUpdate(file);
    this.removeFileSelected();
  };

  closeDataModal = () => {
    const { file, leaseFileUpdate } = this.props;
    this.setState({ showDataModal: false });
    localStorage.removeItem(`leaseDeal_${file.deal}`);
    leaseFileUpdate({});
    this.removeFileSelected();
  };

  handleClick = () => {
    // Get all file list items
    const fileItems = document.querySelectorAll(".file-list-item");
    // Remove the 'selected' and 'disabled' classes from all file list items
    fileItems.forEach((fileItem) => {
      const leaseAbstractionFileSelected = fileItem.querySelector(
        ".lease-abstraction-file-selected"
      );
      if (leaseAbstractionFileSelected) {
        leaseAbstractionFileSelected.classList.remove(
          "lease-abstraction-file-selected"
        );
      }
      const fileSearchIcon = fileItem.querySelector(".fileSearchIcon");
      if (fileSearchIcon) {
        fileSearchIcon.classList.remove("disabled");
      }
    });
    // Add the 'selected' class to the clicked file list item
    this.liElement.classList.add("lease-abstraction-file-selected");
    // Add the 'disabled' class to the file search icons of all other file list items
    fileItems.forEach((fileItem) => {
      if (fileItem !== this.liElement) {
        const fileSearchIcon = fileItem.querySelector(".fileSearchIcon");
        if (fileSearchIcon) {
          fileSearchIcon.classList.add("disabled");
        }
      }
    });
  };

  toggleDropdown(i) {
    this.setState((prevState) => {
      const dropdownOpen = prevState.dropdownOpen.slice();
      dropdownOpen[i] = !dropdownOpen[i];
      return { dropdownOpen };
    });
  }

  closeConfirmationModal = () => {
    this.setState({ showModal: false });
  };

  closeEditMode = () => {
    this.setState({ showEditMode: false });
  };

  handleDeleteFile = (id) => {
    const { deleteFile, file } = this.props;
    deleteFile(id);

    dealsService.updateDeal(file.deal, {
      modified: new Date(),
    });

    this.closeConfirmationModal();
    this.trackDeleteFile();
  };

  handleFileDownload = (id) => {
    const { fetchFilesDownloadLink } = this.props;
    fetchFilesDownloadLink(id, true);
    this.trackDownloadFile();
  };

  handleFileNameChange = (value) => {
    this.setState({ tempTitle: value });
  };

  handlePrivacyChange = (value) => {
    this.setState({ tempPrivacy: value });
  };

  handleSave = () => {
    const { file, updateFile } = this.props;
    const { tempPrivacy, tempTitle } = this.state;
    const params = {
      ...file,
      title: tempTitle,
      privacy: tempPrivacy ? "private" : "team",
    };
    if (tempPrivacy !== file.privacy) {
      this.trackPrivacyChange(tempPrivacy ? "private" : "team");
    }
    if (tempTitle !== file.title) {
      this.trackNameChange(tempTitle);
    }

    updateFile(file.id, params);
    dealsService.updateDeal(file.deal, {
      modified: new Date(),
    });
    this.closeEditMode();
  };

  showConfirmationModal = () => {
    this.setState({ showModal: true });
  };

  showEditMode = () => {
    this.setState({ showEditMode: true });
  };

  trackDeleteFile = () => {
    const {
      analyticProperties: { actionPrefix, categoryPrefix },
    } = this.props;
    userEventService.trackEvent(
      {
        eventAction: "file_deleted",
        eventCategory: "File Action",
      },
      {
        actionPrefix,
        categoryPrefix,
      }
    );
  };

  trackDownloadFile = () => {
    const {
      analyticProperties: { actionPrefix, categoryPrefix },
    } = this.props;
    userEventService.trackEvent(
      {
        eventAction: "file_downloaded",
        eventCategory: "File Action",
      },
      {
        actionPrefix,
        categoryPrefix,
      }
    );
  };

  trackLeaseAbstraction = () => {
    const {
      analyticProperties: { actionPrefix, categoryPrefix },
    } = this.props;
    userEventService.trackEvent(
      {
        eventAction: "detect_data_clicked",
        eventCategory: "File Action",
        eventValue: {
          deal_pk: this.props.file.deal,
          file_pk: this.props.file.id,
          file_name: this.props.file.title,
        },
      },
      {
        actionPrefix,
        categoryPrefix,
      },
    );
  };

  trackNameChange = (eventValue) => {
    const {
      analyticProperties: { actionPrefix, categoryPrefix },
    } = this.props;
    userEventService.trackEvent(
      {
        eventAction: "file_name_changed",
        eventCategory: "File Action",
        eventValue,
      },
      {
        actionPrefix,
        categoryPrefix,
      }
    );
  };

  trackPrivacyChange = (eventValue) => {
    const {
      analyticProperties: { actionPrefix, categoryPrefix },
    } = this.props;
    userEventService.trackEvent(
      {
        eventAction: "file_privacy_changed",
        eventCategory: "File Action",
        eventLabel: `file_edited_from_${actionPrefix}`,
        eventValue,
      },
      {
        actionPrefix,
        categoryPrefix,
      }
    );
  };

  render() {
    const { file, userData } = this.props;
    const {
      showEditMode,
      showModal,
      tempPrivacy,
      tempTitle,
      showDataModal,
      errorMessage,
      successMessage,
    } = this.state;

    return (
      <React.Fragment>
        {Object.keys(file).length !== 0 && (
          <li
            className="file-list-item"
            key={file.id}
            ref={(li) => {
              this.liElement = li;
            }}
          >
            {!showEditMode && (
              <React.Fragment>
                <div className="file-content">
                  <div className="file-icon-wrapper">
                    <Icon iconName="document" />
                    <span>{file.file_type}</span>
                  </div>
                  <div className="file-info">
                    <h3 className="filename">{file.title}</h3>
                    <div>
                      <p className="file-metadata">
                        {file.created &&
                          moment(file.created).format("YYYY-MM-DD")}{" "}
                        |<span className="privacy"> {file.privacy}</span>
                      </p>
                    </div>
                    <p className="error-message-custom">{errorMessage}</p>
                    <p className="success-message-custom">{successMessage}</p>
                  </div>
                </div>
                <div className="file-actions">
                  <Avatar
                    enableHover
                    firstName={file.created_by.first_name}
                    image={file.created_by.avatar}
                    lastName={file.created_by.last_name}
                    sizing="md"
                  />
                  <IconButton
                    iconName="download"
                    onClick={() => this.handleFileDownload(file.id)}
                  />
                  {file.created_by.id === userData.id && (
                    <IconButton
                      iconName="edit"
                      onClick={() => this.showEditMode()}
                    />
                  )}
                  {this.state.lease_abstraction_flag &&
                  this.state.processingCompleted &&
                  !this.state.isResultEmpty &&
                  !errorMessage ? (
                    <LuFileSearch2
                      size={20}
                      onClick={(e) => {
                        this.trackLeaseAbstraction();
                        this.openDataModal(e);
                        this.handleClick(e);
                      }}
                      title="Import Data to Voucher"
                      className="fileSearchIcon"
                    />
                  ) : (
                    this.state.lease_abstraction_flag &&
                    !this.state.isResultEmpty &&
                    !errorMessage && (
                      <div className="loading-icon">
                        <div className="spinner">
                          <img alt="Loading" src={LoadingIcon} />
                        </div>
                      </div>
                    )
                  )}
                </div>
              </React.Fragment>
            )}
            {showEditMode && (
              <div className="edit-file">
                <input
                  type="text"
                  value={tempTitle}
                  onChange={(e) => this.handleFileNameChange(e.target.value)}
                />
                <div>
                  <Checkbox
                    checked={tempPrivacy}
                    onChange={({ target: { checked } }) =>
                      this.handlePrivacyChange(checked)
                    }
                  >
                    Private
                  </Checkbox>
                  <div className="button-controls">
                    <IconButton
                      iconName="bin"
                      onClick={this.showConfirmationModal}
                      variant="basic"
                    />
                    <Button variant="secondary" onClick={this.closeEditMode}>
                      Cancel
                    </Button>
                    <Button onClick={this.handleSave}>Save</Button>
                  </div>
                </div>
              </div>
            )}
          </li>
        )}
        {showModal && (
          <ConfirmationModal
            className="deal-file-delete-confirmation"
            onCloseModal={this.closeConfirmationModal}
            showModal={showModal}
          >
            <h1>Are you sure?</h1>
            <p>This file will be permanently deleted from this deal.</p>
            <footer>
              <Button variant="secondary" onClick={this.closeConfirmationModal}>
                Cancel
              </Button>
              <Button onClick={() => this.handleDeleteFile(file.id)}>
                Delete
              </Button>
            </footer>
          </ConfirmationModal>
        )}
        {showDataModal && (
          <div className="modal-backdrop" onClick={this.hideDataModal}>
            <FileModal
              hideDataModal={this.hideDataModal}
              closeDataModal={this.closeDataModal}
              results={this.state.results}
              file={this.props.file}
              analyticProperties={this.props.analyticProperties}
              deal={this.props.file.deal}
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}

FileListItem.propTypes = {
  analyticProperties: PropTypes.shape({
    actionPrefix: PropTypes.string,
    categoryPrefix: PropTypes.string,
  }),
  deleteFile: PropTypes.func.isRequired,
  fetchFilesDownloadLink: PropTypes.func.isRequired,
  file: PropTypes.shape({
    id: PropTypes.number,
    deal: PropTypes.number,
    document_name: PropTypes.string,
    title: PropTypes.string,
    file_type: PropTypes.string,
    file_size: PropTypes.number,
    created_by: PropTypes.shape({
      id: PropTypes.number,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      avatar: PropTypes.string,
      email: PropTypes.shape({
        work: PropTypes.string,
      }),
    }),
    created: PropTypes.string,
    modified: PropTypes.string,
    can_edit: PropTypes.bool,
    privacy: PropTypes.string,
    is_lease_document: PropTypes.bool,
  }).isRequired,
  updateFile: PropTypes.func.isRequired,
  userData: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};

FileListItem.defaultProps = {
  analyticProperties: {
    actionPrefix: "",
    categoryPrefix: "",
  },
};

const mapDispatchToProps = (dispatch) => {
  return {
    leaseFileUpdate: (file) => dispatch(abstractionLeaseFile(file)),
  };
};

export default connect(null, mapDispatchToProps)(FileListItem);
